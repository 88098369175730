
// ----------- HY 独立主题定制 -----------

// 页面内容推荐宽度 max-1920 min-1280 content-1200
$hy-content-width: 1280px;

// ----- 基础颜色
// 主题色
$hy-color-primary: #2B88FF;
// 主题色-渐变末尾
$hy-color-primary-end: #1955a8;
// 主题色 - 淡
$hy-color-primary-light: lighten($hy-color-primary, 40%);
// 主题渐变
$hy-color-gradient: linear-gradient(90deg, $hy-color-primary, $hy-color-primary-end);
// 主题渐变-变亮
$hy-color-gradient-light: linear-gradient(90deg, lighten($hy-color-primary, 10%), lighten($hy-color-primary-end, 10%));
// 普通背景
$hy-color-bg: #F5F5F5;
// 阴影
$hy-color-shadow: #00000012;
// 分割线
$hy-color-line: #dae7fd;
// 危险 - 红
$hy-color-danger: #fa2c19;
// 警示 - 黄
$hy-color-warn: #ff9e05;
// 成功 - 绿
$hy-color-success: #4eb8cb;
// 信息 - 蓝
$hy-color-info: #196def;

// ----- 文字
// 字色
$hy-color-text-1: #333333;
$hy-color-text-2: #666666;
$hy-color-text-3: #999999;
$hy-color-text-active: $hy-color-primary;
$hy-color-text-red: $hy-color-danger;
$hy-color-text-gray: #dadfef;

// 字号
$hy-font-size-banner-title: 60px;
$hy-font-size-title1: 30px;
$hy-font-size-title2: 24px;
$hy-font-size-title3: 20px;
$hy-font-size-big: 18px;
$hy-font-size-normal: 16px;
$hy-font-size-small: 14px;
$hy-font-size-mini: 12px;

// ----- 容器
// 按钮圆角
$hy-radius-button: 4px;
// 按钮阴影
$hy-shadow-button: 0px 0px 8px 0px rgba(25,34,63,0.1);
// 容器圆角
$hy-radius-container: 4px;
// 容器阴影
$hy-shadow-container: 0px 0px 13px 0px rgba(25,34,63,0.1);

// ----- Z轴定义
$hy-z-global: 9000;
$hy-z-high: 4000;
$hy-z-middle: 1000;
$hy-z-low: 100;

// ---------------- 暴露变量给js层 ----------------
:export {
  colorPrimary: $hy-color-primary;
  colorPrimaryEnd: $hy-color-primary-end;
  colorBg: $hy-color-bg;
  colorText1: $hy-color-text-1;
  colorText2: $hy-color-text-2;
  colorText3: $hy-color-text-3;
}