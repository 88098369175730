@import "common/styles/preload.scss";

.container {
  width: 100%;
  background-color: #fdfdfd;

  .head {
    padding: 0 20px;
    height: 120px;
    background-image: $hy-color-gradient;
    overflow: hidden;

    .title {
      font-size: 30px;
      color: white;
      opacity: 0.9;
    }

    .desc {
      margin-top: 4px;
      font-size: 12px;
      color: white;
      opacity: 0.9;
    }

    img {
      width: 70px;
      height: 70px;
      opacity: 0.8;
    }
  }

  .content {
    padding: 40px 60px;

    input {
      height: 50px;
      padding: 16px;
      background: #EEEEEE;
      border: 1px solid #e5e5e5;
      border-radius: 8px 0 0 8px;
      font-size: 14px;
    }

    input::placeholder {
      color: $hy-color-text-3;
      opacity: 0.7;
    }

    .button {

      width: 100px;
      height: 50px;
      border-radius: 0 8px 8px 0;
      background-image: $hy-color-gradient;
      cursor: pointer;

      .icon {
        width: 30;
        height: 30;
        color: white;
        margin-right: 6px;
      }

      span {
        font-size: 14px;
        color: white;
      }
    }

    .resultContent {

      margin-top: 20px;
      
      .resultIcon {
        margin-right: 20px;
        width: 150px;
        height: 150px;
      }

      .resultTitle {
        margin: 0;
        font-size: 20px;
        font-weight: bold;

        .real {
          color: $hy-color-success;
        }

        .fake {
          color: $hy-color-warn;
        }
      }

      .person {

        margin-top: 10px;
        
        .avatar {
          width: 100px;
          height: 100px;
          border: solid 1px #DDDDDD;
        }

        .name {
          font-size: 16px;
        }

        .desc {
          margin-top: 10px;
          font-size: 16px;
        }
      }
    }
  }

  hr {
    margin: 0 20px;
    outline: none;
    border: none;
    height: 1px;
    background-color: #DDDDDD;
  }

  .footer {

    padding: 40px 60px;
    font-size: 13px;
    color: $hy-color-text-3;
  }
}
