@import "common/styles/preload.scss";

.container {
  position: fixed;
  z-index: 999;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);

  .item {
    width: 60px;
    height: 60px;
    background-color: white;
    box-shadow: 0px 0px 8px 0px rgba(57, 95, 111, 0.17);
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    .icon {
      position: relative;
      width: 24px;
      height: 24px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    h3 {
      margin: 7px 0 0 0;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .item:not(:last-of-type) {
    margin-bottom: 8px;
  }

  .item:hover {
    transform: scale(1.1, 1.1);
    background-color: $hy-color-primary;

    .icon {
      img {
        filter: drop-shadow(30px 0 white);
        -webkit-filter: drop-shadow(30px 0 white);
        transform: translate(-30px, 0);
        -webkit-transform: translate(-30px, 0);
        -ms-transform: translate(-30px, 0);
        -o-transform: translate(-30px, 0);
      }
    }

    h3 {
      color: white;
    }
  }
}
