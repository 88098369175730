@import "common/styles/preload.scss";

// --------- Flex布局 ----------

// 横向布局
.flex-row {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
}

.flex-row-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.flex-row-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

// 纵向布局
.flex-col {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.flex-col-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-col-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

// 主轴居中
.flex-main-axis-center {
  justify-content: center;
}

// 主轴Space between
.flex-main-axis-between {
  justify-content: space-between;
}

// 主轴Space evenly
.flex-main-axis-evenly {
  justify-content: space-evenly;
}

// 主轴Start
.flex-main-axis-start {
  justify-content: flex-start;
}

// 主轴End
.flex-main-axis-end {
  justify-content: flex-end;
}

// 纵轴居中
.flex-cross-axis-center {
  align-items: center;
}

// 纵轴Start
.flex-cross-axis-start {
  align-items: flex-start;
}

// 纵轴End
.flex-cross-axis-end {
  align-items: flex-end;
}

// 伸展比例
.flex-grow1 {
  flex-grow: 1;
}

// 收缩比例
.flex-shrink0 {
  flex-shrink: 0;
}